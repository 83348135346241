import { AvatarTypes, LobbyRoom, FREE_TIER } from "@chesshotel/constants";
import { createReduxModule } from "hooks-for-redux";
import { initWebsocketConnect } from "../services/websocket";
// import { setTier } from "./membership";

interface UserData {
  username: string;
  isGuest: boolean;
  id: number;
  room: LobbyRoom;
  avatarId: number;
  avatarType: AvatarTypes;
  fbId: number;
  email: string;
  created: Date;
  country: string;
  activated: number;
}

const initialState = {
  memberLoggedIn: false,
  serverLoggedIn: false,
  isDefaultGuest: true,
  guestInMemberRoom: false,
  displayName: null,
  isGuest: null,
  username: null,
  created: null,
  country: null,
  room: null,
  fbId: null,
  id: null,
  avatarId: null,
  avatarType: AvatarTypes.GUEST,
  avatarUpdateCount: 0,
  emailNotActivated: false,
  email: null,
};

export const [
  useUser,
  {
    setUser,
    updateUsername,
    serverLoggedIn,
    setFbId,
    setAvatarId,
    setAvatarUpload,
    resetUser,
  },
  userStore,
] = createReduxModule("user", initialState, {
  setUser: (state, userData: UserData) => ({
    ...state,
    ...userData,
    memberLoggedIn: !userData.isGuest,
    avatarType: userData.avatarType || AvatarTypes.GUEST,
    displayName: userData.isGuest
      ? userData.username.substring(2)
      : userData.username,
    // guestInMemberRoom: userData.isGuest && userData.room === ROOM.MEMBERS,
    isDefaultGuest: userData.username.substring(0, 7) === "G*Guest",
    emailNotActivated: userData.email && userData.activated != 1,
    email: userData.email,
  }),
  updateUsername: (state, username) => ({
    ...state,
    username,
    displayName: state.isGuest ? username.substring(2) : username,
  }),
  serverLoggedIn: (state, loggedIn) => ({ ...state, serverLoggedIn: loggedIn }),
  setFbId: (state, fbId) => ({
    ...state,
    fbId,
    avatarType: AvatarTypes.FACEBOOK,
    avatarUpdateCount: state.avatarUpdateCount + 1,
  }),
  setAvatarId: (state, avatarId) => ({
    ...state,
    avatarId,
    avatarType: AvatarTypes.DEFAULT,
    avatarUpdateCount: state.avatarUpdateCount + 1,
  }),
  setAvatarUpload: (state) => ({
    ...state,
    avatarType: AvatarTypes.UPLOAD,
    avatarUpdateCount: state.avatarUpdateCount + 1,
  }),
  resetUser: () => initialState,
});

export const LOGIN_GUEST = "LOGIN_GUEST";

let usernameCount = 2;
let atemptedUsername = null;

export function loginGuest(username) {
  // const guests = getState().siteData.guestsOnline;
  // const members = getState().siteData.membersOnline;
  // const gotoMembersRoom = (guests - 200) > members && username && username.substring(0, 5) !== "Guest";

  atemptedUsername = "G*" + username;
  const isGuest = true;
  const room = LobbyRoom.MEMBERS; // gotoMembersRoom ? ROOM.MEMBERS : ROOM.GUESTS;
  const userId = Math.round(Math.random() * -1000000);

  atemptedUsername =
    sessionStorage.getItem("guestUsername") || atemptedUsername;

  // loginServer(atemptedUsername, isGuest, userId, room);

  setUser({
    username: atemptedUsername,
    isGuest,
    id: userId,
    room,
    avatarId: null,
    fbId: null,
    avatarType: AvatarTypes.GUEST,
    email: null,
    created: null,
    country: null,
    activated: null,
  });

  import("./membership").then(({ setTier }) => setTier(FREE_TIER));
  // setTier(FREE_TIER);

  // connectToWebSocket(() =>
  //   loginServer(atemptedUsername, isGuest, userId, room)
  // );
  initWebsocketConnect();
}

export function duplicateUser() {
  const username = atemptedUsername + usernameCount;
  usernameCount++;
  // const isGuest = true;
  // const room = LobbyRoom.MEMBERS;
  // const userId = Math.round(Math.random() * -1000000);

  // loginServer(username, isGuest, userId, room);
  updateUsername(username);
  initWebsocketConnect();
}

export function saveGuestName() {
  const guestUsername = userStore.getState().username;
  sessionStorage.setItem("guestUsername", guestUsername);
}

export function clearGuestName() {
  sessionStorage.removeItem("guestUsername");
}
