import { api } from "./api";
import { userStore } from "../ducks/user";
// import Cookies from "universal-cookie";
// import { nanoid } from "nanoid";
import { v4 } from "uuid";

// import {
//   setOffline,
//   setRetries,
//   resetRetries,
//   setWsClose,
//   setWsCloseErrorMsg,
//   setWsError,
//   setDisconnectTime,
//   pingStore,
//   // setClientDisconn,
// } from "../ducks/ping";
import {
  doStartReconnectTimer,
  endReconnectTimer,
  setOtherLoginShow,
  setWebsocketUrl,
  // setWebsocketUrl,
  updateReconnectTimer,
  wsConnectionStore,
} from "../ducks/wsConnection";

import { routeIncommingMessage } from "./wsInRoutes";
import { LoginWs } from "./wsOutRoutes";
// import { gamePlayStore } from "../ducks/gamePlay";
// import { signInStatus } from "../ducks/signIn";
// import { Unsubscribe } from "redux";
// import { growthbook } from "../util/growthbook";
// import { gamePlayStore } from "../ducks/gamePlay";
// import { growthbook } from "../util/growthbook";

export let websocket = null;

// New for each browser or device
// export function getOrCreateBrowserId() {
//   let browserId: string = localStorage.getItem("browserId");
//   if (!browserId) {
//     browserId = nanoid();
//     localStorage.setItem("browserId", browserId);
//   }
//   return browserId;
// }

// New for each browser tab
// The same for each reconnect
export function getOrCreateSessionId() {
  let sessionId: string = sessionStorage.getItem("sessionId");
  if (!sessionId) {
    sessionId = v4();
    sessionStorage.setItem("sessionId", sessionId);
  }
  return sessionId;
}

// export function loginServer(
//   username: string,
//   isGuest: boolean,
//   userId: number,
//   room: string,
//   gameId = null
// ) {
//   const user = {
//     username,
//     isGuest,
//     userId,
//     room,
//     gameId,
//     browserId: getOrCreateBrowserId(),
//     sessionId: getOrCreateSessionId(),
//   };

//   if (websocket && websocket.readyState === websocket.OPEN) {
//     LoginWs.loginServer(user);
//   } else {
//     setTimeout(() => {
//       loginServer(username, isGuest, userId, room, gameId);
//     }, 100);
//   }
// }

async function loginServer() {
  const { username, isGuest, id, room } = userStore.getState();
  import("../ducks/gamePlay").then(({ gamePlayStore }) => {
    const { gameId } = gamePlayStore.getState();

    try {
      const user = {
        username,
        isGuest,
        userId: id,
        room,
        gameId,
        // browserId: getOrCreateBrowserId(),
        sessionId: getOrCreateSessionId(),
      };

      LoginWs.loginServer(user);
    } catch (e) {
      setTimeout(() => {
        loginServer();
      }, 3000);
    }
  });
}

function startReconnectTimer() {
  const reconnectStart = wsConnectionStore.getState().reconnectStart;
  if (!reconnectStart) {
    doStartReconnectTimer();

    const loop = () => {
      const startTime = wsConnectionStore.getState().reconnectStart;
      if (startTime == null) {
        return;
      }
      updateReconnectTimer();
      requestAnimationFrame(loop);
    };

    loop();
  }
}

// let reconnecting = false;
let pingInterval: any;
// let disconnectedTimeout: any;

export function initWebsocketConnect() {
  api
    .get(`/site/settings?_=${new Date().getTime()}`)
    .then((response) => {
      const { ws } = response.data;
      setWebsocketUrl(ws);
      connectToWebSocket();
    })
    .catch((error) => {
      console.log("get ws number error", error);
      setTimeout(() => {
        initWebsocketConnect();
      }, getDelay());
    });
}

//Happens when server code update happens and it switches to a new server instance
export async function checkWs(ws: number) {
  import("../ducks/gamePlay").then(({ gamePlayStore }) => {
    const { gameEnded } = gamePlayStore.getState();

    const isPlaying = !gameEnded;
    if (isPlaying) {
      const unsubscribe = gamePlayStore.subscribe(({ gameEnded }) => {
        if (gameEnded && wsConnectionStore.getState().ws !== ws) {
          setTimeout(() => {
            unsubscribe();
            setWebsocketUrl(ws);
            connectToWebSocket();
          }, 2000);
        }
      });
    }

    if (!isPlaying && wsConnectionStore.getState().ws !== ws) {
      setWebsocketUrl(ws);
      connectToWebSocket();
    }
  });
}

export function otherUserLogin() {
  disconnectWebSocket();
  setOtherLoginShow(true);
}

function disconnectWebSocket() {
  if (websocket) {
    websocket.onclose = function () {};
    websocket.close();
  }
}

function onOnline() {
  // location.reload();
  console.log("onOnline");
  import("../ducks/ping").then((module) => {
    module.resetRetries(); // Reset the retry counter or adjust accordingly
  });
  initWebsocketConnect(); // Immediately try to reconnect
}

const initialDelay = 1000; // Start with a 1-second delay
const maxDelay = 30000; // Cap the delay at 30 seconds
let retries = 0;
function getDelay() {
  let delay = Math.min(maxDelay, initialDelay * Math.pow(2, retries));
  delay = delay / 2 + (Math.random() * delay) / 2;
  return delay;
}

function connectToWebSocket() {
  disconnectWebSocket();

  const url = wsConnectionStore.getState().websocketUrl;

  websocket = new window.WebSocket(url);

  websocket.onerror = (error: Event) => {
    import("../ducks/ping").then((module) => {
      module.setWsError();
    });
  };

  websocket.onclose = (e: CloseEvent) => {
    console.log("websocket close");
    import("../ducks/ping").then((module) => {
      module.setWsClose();
      module.setWsCloseErrorMsg(e.code + " " + e.reason);
      module.setDisconnectTime();
    });

    setTimeout(() => {
      import("../ducks/ping").then((module) => {
        module.setRetries();
      });
      retries++;
      startReconnectTimer();
      initWebsocketConnect();
    }, getDelay());
  };
  websocket.onopen = () => {
    console.log("websocket open");
    endReconnectTimer();

    startPing();

    import("../ducks/lobby").then((module) => {
      module.clearInvites();
      module.enterLobby();
    });

    window.removeEventListener("online", onOnline);
    window.addEventListener("online", onOnline);

    window.addEventListener("offline", (event) => {
      import("../ducks/ping").then((module) => {
        module.setOffline();
      });
      const timeOut = setTimeout(() => {
        startReconnectTimer();
      }, 3000);
      window.addEventListener("online", (event) => {
        clearTimeout(timeOut);
        endReconnectTimer();
      });
    });

    loginServer();
  };

  websocket.onmessage = async (e) => {
    if (e.data === "pong") {
      // clearTimeout(disconnectedTimeout);
      return;
    }

    let data: any;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
      console.log("JSON parse error", e);
      return;
    }
    routeIncommingMessage(data);
  };

  websocket.sendPing = () => {
    try {
      websocket.send("ping");
    } catch (e) {
      setTimeout(() => {
        websocket.sendPing();
      }, 1000);
    }
  };
}

function startPing() {
  clearInterval(pingInterval);

  pingInterval = setInterval(() => {
    try {
      if (websocket && websocket.readyState == WebSocket.OPEN) {
        websocket.sendPing();
      }
    } catch (e) {
      console.log(e);
    }
  }, 15000);
}
